@tailwind base;
@tailwind components;
@tailwind utilities;


@layer base {
    body {
        font-family: 'Lato', sans-serif;
        background: #FAFAFA;
    }
}
 